<template>
    <section>
        <UsersFilters @on-filter-change="loadAsyncData" />
        <b-table
            class="table-striped overflow-x-scroll"
            backend-sorting
            :data="users.data"
            :striped="true"
            :mobile-cards="true"
            :sticky-header="false"
            @sort="onSort"
        >
            <b-table-column
                sortable
                field="id"
                label="ID"
                width="40"
                numeric
                v-slot="props"
            >
                {{ props.row.id }}
            </b-table-column>
            <b-table-column
                sortable
                field="name"
                :label="$t('companies.name')"
                v-slot="props"
            >
                {{ props.row.name }}
            </b-table-column>
            <b-table-column
                sortable
                field="job_title"
                :label="$t('companies.function')"
                v-slot="props"
            >
                {{ props.row['job_title'] }}
            </b-table-column>
            <b-table-column
                sortable
                field="email"
                :label="$t('companies.email')"
                v-slot="props"
            >
                {{ props.row.email }}
            </b-table-column>
            <b-table-column
                sortable
                field="locale"
                :label="$t('companies.language')"
                v-slot="props"
            >
                {{ props.row.locale }}
            </b-table-column>
            <b-table-column
                sortable
                field="locale"
                :label="$t('companies.user.active.label')"
                :title="$t('companies.user.active.actived')"
                v-slot="props"
            >
                <i
                    data-toggle="modal"
                    :data-target="'#modalDelete' + props.row.id"
                    :class="`fas fa-${
                        props.row.active ? 'toggle-on' : 'toggle-off'
                    }`"
                ></i>
                <ModalDeleteSave
                    :item="
                        modalDelete(
                            props.row.id,
                            props.row.name,
                            props.row.active
                        )
                    "
                />
            </b-table-column>

            <b-table-column :label="$t('companies.actions')" v-slot="props">
                <div>
                    <router-link
                        :to="`/company/users/${props.row.id}`"
                        class="inline-flex items-center px-3 py-2 -mt-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        <IconSearch class="h-4 w-4" />
                    </router-link>
                    <router-link
                        :to="`/company/user/${props.row.id}/edit`"
                        class="inline-flex px-3 py-2 ml-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        <IconModif class="h-4 w-4" />
                    </router-link>
                </div>
            </b-table-column>

            <b-table-column v-slot="props">
                <div
                    v-if="
                        props.row.id != userId &&
                        props.row.user_type != 1 &&
                        !isUnderImpersonation
                    "
                >
                    <button
                        :disabled="
                            getUserImpersonated &&
                            props.row.id == getUserImpersonated.id
                        "
                        @click.prevent="impersonate(props.row.id)"
                        class="btn border-transparent px-3 items-center py-2 ml-2 shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-400"
                    >
                        <span>
                            {{ $t('impersonation.description') }}
                        </span>
                    </button>
                </div>
            </b-table-column>
        </b-table>
        <Pagination
            v-if="users"
            :total-results="users.total"
            :total-pages="Math.ceil(users.total / users.per_page)"
            :per-page="users.per_page"
            :current-page="users.current_page"
            @pagechanged="onPageChange"
        />

        <b-loading
            v-model="loading"
            :can-cancel="false"
            :is-full-page="true"
        ></b-loading>
    </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Pagination from '@/components/Model/Post/Pagination'
import UsersFilters from '@/components/Model/Company/UsersFilters'
import ModalDeleteSave from '@/components/UI/ModalDeleteSave'

export default {
    name: 'TabUsers',
    components: {
        ModalDeleteSave,
        Pagination,
        UsersFilters
    },
    data: function () {
        return { userId: '', loading: false }
    },
    mounted() {
        this.userId = JSON.parse(localStorage.getItem('user')).id
        console.log(this.userId)
    },
    computed: {
        ...mapState('company', {
            users: (state) => state.users,
            config: (state) => state.usersConfig
        }),
        ...mapState('admin', {
            isUnderImpersonation: (state) => state.isUnderImpersonation
        }),
        ...mapGetters('admin', ['getUserImpersonated'])
    },
    methods: {
        ...mapActions('admin', ['impersonateUser']),
        impersonate(userId) {
            this.loading = true
            setTimeout(() => {
                this.impersonateUser(userId).then(() => {
                    this.$store.dispatch('toast', {
                        type: 'success',
                        title: this.$t('success.title')
                    })
                    this.loading = false
                })
            }, 1000)
        },
        modalDelete: function (id, name, status) {
            const deleteLink = localStorage.getItem('baseURL') + '/users/' + id
            var deleteUser = {
                id: id,
                name: name,
                deleteLink,
                object: "l'utilisateur",
                action: status == 1 ? 'Supprimer' : 'Sauvegarder'
            }
            return deleteUser
        },
        onSort(field, order) {
            this.config.page = 1
            this.config.sort = field
            this.config.sortDirection = order
            this.loadAsyncData()
        },
        onPageChange(page) {
            this.config.page = page
            this.loadAsyncData()
        },
        loadAsyncData() {
            this.$emit('get-data', this.config)
        }
    }
}
</script>
